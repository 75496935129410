body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

.up-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.linkto {
  text-decoration: underline !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.slide {
  background-color: black;
  width: fit-content;
  width: 412px;
  height: 254px;
  border-radius: 16px;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.slide.active {
  opacity: 1;
}
.mask {
  background-image: url("./Assets/Mac_Pro.svg");
  width: 423px;
  height: 350px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.regular {
  font-weight: 500;
  font-size: 14px;
}
.separator {
  margin-right: 32vw;
  margin-left: 50px;
}
.contact-column {
  width: 100%;
}

a {
  font-weight: 500;
  text-decoration: none;
  color: black;
}
h2 {
  font-size: 42px;
}
p {
  font-size: 13px;
  line-height: 1.7;
  font-weight: 300;
}
body {
  font-family: "Montserrat", sans-serif;
}
.right-section-header {
  margin-left: 50px;
  margin-right: 50px;
  /* padding-right: 61px; */
}
.header {
  height: 205px;
  display: flex;
  margin-left: 50px;
  margin-top: 20px;
  margin-right: 40px;
}
.foto-manuel {
  border-radius: 50%;
  width: 180px;
  height: 180px;
  background-image: url("./Assets/foto_cv_manuel.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-two {
  width: 63%;
  display: flex;
  margin-left: 50px;
  margin-top: 25px;
  margin-bottom: 50px;
}

h4 {
  font-weight: 500;
}

.academica-section {
  margin-left: 40px;
}
.controls {
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
  margin-top: 30px;
  width: 423px;
}

.current-slide-title {
  height: 80px;
}
.subtitle {
  text-decoration: underline;
}
.controls button {
  margin: 0 10px;
}
.prev-icon {
  background-image: url("./Assets//prev-icon.svg");
  background-size: cover;
  width: 36px;
  height: 36px;
}

.next-icon {
  background-image: url("./Assets//next-icon.svg");
  background-size: cover;
  width: 36px;
  height: 36px;
}

.mobile-cont {
  /* height: 80vh; */
  /* width: 90vw; */
}

svg {
  color: black;
}

.cont-accordion {
  max-width: 600px;
  margin: 0 auto;
}

.accordion-item {
  border-bottom: 1px solid #ccc;
}

.accordion-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #f9f9f9;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
}

.accordion-title:hover {
  background-color: #f0f0f0;
}

.accordion-content {
  padding: 15px;
  background-color: transparent;
  display: none;
}

.accordion-item.active .accordion-title {
  background-color: transparent;
}

.accordion-item.active .accordion-content {
  display: block;
}
.head-mb {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
@media (max-width: 700px) {
  .mask {
    width: 339px;
    height: 280px;
  }
  .slide {
    /* background-color: black; */
    /* width: fit-content; */
    width: 330px;
    height: 205px;
  }
  .section-two {
    margin: 0;
    height: auto;
    width: 100%;
    justify-content: center;
  }
  .academica-section {
    margin: 0px;
  }
  .slide-title-container {
    width: 90%;
  }
  .academica-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .prev-icon {
    margin-right: 60px;
  }
  .controls {
    width: auto;
  }
  .proyect-h4 {
    display: none;
  }
  h2 {
    font-size: 33px;
  }
}
